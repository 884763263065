<template>
  <v-toolbar
    v-scroll="onScroll"
    app
    :dark="!isScrolling"
    :light="isScrolling"
    :color="!isScrolling ? 'transparent' : 'white'"
    :flat="!isScrolling"
  >
    <v-toolbar-title class="headline text-uppercase">
      <v-img
        src="/favicon.ico"
        class="mr-2"
        height="32"
        width="32"
        style="display: inline-block; vertical-align: bottom"
      />
      <span class="font-weight-bold">Caleb</span>
      <span class="font-weight-light">Bergman</span>
    </v-toolbar-title>
    <v-spacer />
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-btn
        v-for="(route, i) in menu"
        class="font-weight-light"
        flat
        large
        :active-class="!isScrolling ? 'primary--text' : undefined"
        :class="{'v-btn--active': currentRoute.includes(route.name) }"
        :href="route.path"
        :key="i"
        @click="$router.push(route.path)"
      >
        <span v-text="route.text" />
      </v-btn>
    </template>
    <v-btn v-else icon @click="toggleDrawer">
      <v-icon>mdi-menu</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import { createNamespacedHelpers, mapMutations } from 'vuex'
const { mapGetters } = createNamespacedHelpers('app')

export default {
  data: () => ({
    isScrolling: false
  }),

  computed: {
    ...mapGetters(['menu']),
    currentRoute() {
      return this.$route.name || ''
    }
  },

  methods: {
    ...mapMutations(['toggleDrawer']),
    onScroll() {
      this.isScrolling = (window.pageYOffset || document.documentElement.scrollTop || 0) > 100
    }
  }
}
</script>
